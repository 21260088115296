<template>
  <base-page-layout :title="$route.meta.title">
    <v-card class="mt-3" flat>
      <v-card-text>
        <iframe id="integraClientUrl" class="integraClient" :src="integraClientUrl" title="Integra Client" />
      </v-card-text>
    </v-card>
  </base-page-layout>
</template>

<script>
export default {
  name: 'IntegraClient',
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue')
  },
  computed: {
    integraClientUrl() {
      return process.env.VUE_APP_INTEGRA_CLIENT || 'https://integraclient-beta.mercafacil.com'
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessageFromIframe)
    // timeout will be removed after testing
    setTimeout(() => {
      this.sendMessageToIframe()
    }, 3500)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessageFromIframe)
  },
  methods: {
    receiveMessageFromIframe(event) {
      if (this.integraClientUrl === event.origin) {
        this.sendMessageToIframe()
      }
    },
    sendMessageToIframe() {
      const { username } = this.$getLoggedUser()
      const iframe = document.getElementById('integraClientUrl')
      iframe.contentWindow.postMessage({ sessionID: localStorage.getItem('session_id'), usermail: username }, this.integraClientUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.integraClient {
  width: 100%;
  height: 75vh;
}
</style>
